import { useLocation } from "@remix-run/react";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import useWhoAmI from "./useWhoAmI";

export type FilterValues = {
  [tenantId: string]: {
    [pathname: string]: {
      [searchParamKey: string]: string | string[];
    };
  };
};

export const PAGE_FILTERS_STORAGE_KEY = "pageFilters";
export const filterValuesAtom = atomWithStorage<FilterValues>(
  PAGE_FILTERS_STORAGE_KEY,
  {} as FilterValues
);

export const useFilterValuesState = (pathname?: string) => {
  const [filterValues, setFilterValues] = useAtom(filterValuesAtom);
  const whoAmI = useWhoAmI();
  const tenantId = whoAmI?.tenantId;
  const location = useLocation();

  const internalPathname = pathname ?? location.pathname;

  function getFilterValue() {
    if (whoAmI?.tenantId) {
      return filterValues?.[whoAmI?.tenantId]?.[internalPathname];
    }
  }

  function removeFilterValue(searchParamKey: string) {
    if (tenantId) {
      setFilterValues((values) => {
        delete getFilterValue()?.[searchParamKey];
        return { ...values };
      });
    }
  }

  function updateFilterValue(value: string | string[], searchParamKey: string) {
    if (tenantId) {
      setFilterValues((values) => {
        return {
          ...values,
          [tenantId]: {
            ...values[tenantId],
            [internalPathname]: {
              ...values[tenantId]?.[internalPathname],
              [searchParamKey]: value,
            },
          },
        };
      });
    }
  }

  function getFilterValueForParam(searchParamKey: string) {
    return getFilterValue()?.[searchParamKey];
  }

  return {
    filterValues,
    setFilterValues,
    updateFilterValue,
    getFilterValue,
    getFilterValueForParam,
    removeFilterValue,
  };
};
